import React from "react"
// import { useStaticQuery, graphql } from "gatsby"

export default function Banner() {
  // const data = useStaticQuery(graphql`
  //   query BannerMetadataQuery {
  //     site {
  //       siteMetadata {
  //         email
  //       }
  //     }
  //   }
  // `)
  // const { siteMetadata } = data.site
  return (
    <div className='banner'>
      <div className="slider-wrapper theme-default">
        <div id="slider" className="nivoSlider">
          <img src="./images/banner1.jpg" data-thumb="./images/banner1.jpg" alt="Tractors in Field" />
          <img src="./images/banner2.jpg" data-thumb="./images/banner2.jpg" alt="Tractors with sprayers" />
          <img src="./images/banner3.jpg" data-thumb="./images/banner3.jpg" alt="Tractors in Field" />
          <img src="./images/banner4.jpg" data-thumb="./images/banner4.jpg" alt="View of farm from field" />
          <img src="./images/banner5.jpg" data-thumb="./images/banner5.jpg" alt="View of farm from field" />
          <img src="./images/banner6.jpg" data-thumb="./images/banner6.jpg" alt="Farm buildings" />
          <img src="./images/banner7.jpg" data-thumb="./images/banner7.jpg" alt="Farm buildings" />
          <img src="./images/banner8.jpg" data-thumb="./images/banner8.jpg" alt="Tractor in Field" />
        </div>
      </div>
    </div>
  );
}

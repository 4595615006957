import React from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function Headers() {
  const data = useStaticQuery(graphql`
    query HeadersMetadataQuery {
      site {
        siteMetadata {
          email
          subtitle
          address
          phone
          fax
        }
      }
    }
  `)
  const { siteMetadata } = data.site
  const emailMailTo = "mailto:" + siteMetadata.email
  return (
    <header>
      <div className='left'>
          <span className='title'><img src="images/logo.png" alt="logo"/></span>
          <hr />
          <span className='subtitle'>{siteMetadata.subtitle}</span>
      </div>
      <div className='right'>
          <span className='contact'>Tel: <em>{siteMetadata.phone}</em>  |  Fax: <em>{siteMetadata.fax}</em><br />Email: <a href={emailMailTo}>{siteMetadata.email}</a><br />{siteMetadata.address}</span>
      </div>
      <div className='clearfloat'></div>
    </header>
  );
}

import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../components/Header"
import HomeSection from "../components/HomeSection"
import Footer from "../components/Footer"

export default function Index() {
  const data = useStaticQuery(graphql`
    query siteMetadataQuery {
      site {
        siteMetadata {
          title
          description
          url
        }
      }
    }
  `)
  const { siteMetadata } = data.site
  return (
    <React.Fragment>
      <Helmet bodyAttributes={{class: 'is-preload'}}>
        <title>{siteMetadata.title}</title>
        <link rel="canonical" href={siteMetadata.url} />
        <meta name="description" content={siteMetadata.description} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <html lang="en" />
      </Helmet>
      <Header/>
      <HomeSection/>
      <Footer/>
    </React.Fragment>
  );
}

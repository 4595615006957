import React from "react"

export default function Footer() {
  return (
    <footer>
      <div className='left'>
        <small>Copyright Vinny Farms. Powered by <a href="https://www.gatsbyjs.org">Gatsby</a> and <a href="https://www.netlify.com">Netlify</a>.</small>
      </div>
      <div className='right'>
          <small><a href='/admin'>Administrator Login</a></small>
      </div>
      <div className='clearfloat'></div>
    </footer>
  );
}

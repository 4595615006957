import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Banner from "../components/Banner"

export default function HomeSection({data}) {
  const queryData = useStaticQuery(graphql`
    query HomeSectionQuery {
      markdownRemark(frontmatter: {section: {eq: "home"}}) {
        frontmatter {
          title
        }
        html
      }
    }
  `)
  if (!data) {
    data = queryData
  }
  const { markdownRemark } = data
  const { html, frontmatter } = markdownRemark
  const { title } = frontmatter
  return (
    <section className="mainContent">
      <Banner/>
      <h1>{title}</h1>
      <article>
        <div
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
    </section>
  );
}
